import React from "react";
import { NavLink } from "react-router-dom";
import styled, { css, keyframes } from "styled-components";

import OSImage from '../../assets/images/OpenSea2x.png'
import DiscordImage from '../../assets/images/Discord2x.png'
import TIcon from '../../assets/images/ticon.png'

import MainLogo from '../../assets/images/YokaiKindgom_White-1.png'

export const Footer = () => {
  return (
        <FooterWrap>
          <MainBody>
            <FirstSection>
              <LogoImage src={MainLogo} />
              <FirstSectionLinks>
                <Link to="/privacy">
                  <SectionText>Privacy policy</SectionText>
                </Link>
                &nbsp;|&nbsp;
                <Link to="/terms">
                  <SectionText>Terms &amp; Conditions</SectionText>
                </Link>
              </FirstSectionLinks>
            </FirstSection>

            <SecondSection>
              <FooterLinks>
                <FooterItem imageFile={OSImage} href="https://opensea.io/collection/yokai-kingdom" target="_blank" ></FooterItem>
                <FooterItem imageFile={DiscordImage} href="https://discord.gg/FncuawMDyZ" target="_blank" ></FooterItem>
                <FooterItem imageFile={TIcon} href="https://twitter.com/yokai_kingdom" target="_blank" ></FooterItem>
              </FooterLinks>
              <SectionText style={{color: 'white'}}>© Copyright YOKAI KINGDOM 2022</SectionText>
            </SecondSection>

          </MainBody>
        </FooterWrap>
  );
}





const LogoImage = styled.img`
  width: 100px;
  height: auto;

`
const FooterWrap = styled.div`
  display: flex;
  padding: 20px;
  padding-left: 60px;
  padding-right: 60px;


  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-color: #000000;
`;

const MainBody = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;

  @media (max-width: 600px) {
    flex-direction: column;

  }
`;

const FirstSection = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  color: white;
  justify-content: center;
  align-items: flex-start;
  font-family: Quattrocento;
  flex-direction: column;
  font-weight: 700;
  @media (max-width: 600px) {
    align-items: center;

  }
`;

const SecondSection = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: flex-end;
  align-items: flex-end;
  font-family: Quattrocento;
  font-weight: 700;
  @media (max-width: 600px) {

    align-items: center;
  }
`;

const FirstSectionLinks = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  justify-content: center;
  align-items: flex-end;
  @media (max-width: 950px) {

  }
`;

const SectionText = styled.p`
  position: static;
  z-index: 10;
  margin-bottom: 0px;
  padding-top: 50px;

  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
`;

const FooterLinks = styled.div`
  position: relative;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 600px) {

    padding-top: 30px;
  }
`;

const Link = styled(NavLink)`

  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  opacity: 1;
  -webkit-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
  color: white;
  text-decoration: none;

  font-family: Quattrocento;
  font-weight: 700;

  :hover {
    color: #B4B092;
  }

`;

const FooterItem = styled.a<{imageFile: string}>`

  display: block;
  width: 40px;
  height: 40px;
  max-height: 40px;
  max-width: 40px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;

  background-image: ${({ imageFile }) =>
    css`
        url(${imageFile})
        `};


  background-position: 50% 50%;
  background-size: 35px;
  background-repeat: no-repeat;
  opacity: 1;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1px;

  :hover {
    opacity: 0.8;
  }

`;
