import React, { ReactNode } from 'react'
import styled from "styled-components";

interface MainProps {
  children: ReactNode
}

export const Main = ({children}: MainProps) => {

  return (
    <View>
      <ViewInner>
        {children}
      </ViewInner>
    </View>
  )
}

const View = styled.main`
  position: relative;
`;

const ViewInner = styled.div`
  max-width: 100%;
`;
