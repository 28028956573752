import React, {useState} from 'react'
import axios from 'axios'
import { Screen } from '../../common/layout_account/Screen'
import styled from "styled-components";

export const LeaderboardScreen = () => {
  const [leaderboardData, setLeaderBoardData] = useState<any[]>([])
  const [filter, setFilter] = useState(0)

  React.useEffect(() => {
    getInfo()

    return () => {
    }
  }, []);


  const getInfo = async() => {

    const INFO_URL = 'https://vipngjt7islzr3wvx2alhtqhjy0uagss.lambda-url.us-east-1.on.aws'
    var sessions = {}

  var XP = {
    common: 10,
    elite: 50,
    boss: 100,
    gems: 2
  }
  var classes_played = {
    'Ninja': 0,
    'Worlokk': 0,
    'Sohei': 0
  }

  var regions_played = {

  }

  var leaderboard_data: any = []
  var data: any = await axios.create().get(INFO_URL);
  data = data.data
    console.log((data.message.length))
    const saves = data.message
    for(let i in saves){
      sessions[saves[i].UserEmail['S']] = sessions[saves[i].UserEmail['S']] ? (sessions[saves[i].UserEmail['S']] + 1) : 1

      let hero = JSON.parse(saves[i].GameSave['S'])['PersistentHeroes'][0]
      let region = JSON.parse(saves[i].GameSave['S'])['RegionData']
      var xp_count = 0;
      for(let i in region){
        regions_played[region[i]['Name']] = regions_played[region[i]['Name']] ? regions_played[region[i]['Name']] + 1 : 1

        if(region[i]['Encounters']){
          for(let j in region[i]['Encounters']){
            if(region[i]['Encounters'][j].includes('Elite')){
              xp_count += XP['elite']
            } else if (region[i]['Encounters'][j].includes('Boss')){
              xp_count += XP['boss']
            } else {
              xp_count += XP['common']
            }
          }
          xp_count += JSON.parse(saves[i].GameSave['S'])['GemCount']
        }
      }

      if(xp_count != 0){
        leaderboard_data.push({
          account: saves[i].UserEmail['S'],
          xp: xp_count,
          hero: hero.Name
        })
      }


      classes_played[hero['Name']]++
    }

    let entries = Object.entries(sessions);
    // [["you",100],["me",75],["foo",116],["bar",15]]

    let sorted = entries.sort((a, b) => Number(a[1]) - Number(b[1]));
    // [["bar",15],["me",75],["you",100],["foo",116]]
    console.log(sorted);

    console.log(classes_played)
    console.log(regions_played)

    leaderboard_data.sort((a,b) => b.xp - a.xp); // b - a for reverse sort

    console.log(leaderboard_data)

    setLeaderBoardData(leaderboard_data)
  }

  const getLeaderboardRow = (account, hero, xp, key) => {

    if(filter == 0 || (filter == 1 && hero == 'Ninja') || (filter == 2 && hero == 'Worlokk') || filter == 3 && hero == 'Sohei'){
      return (
        <>
        <Rank>
          {key}
        </Rank>
        <Player>
          {account.split('@')[0]}
        </Player>
        <Hero>
          {hero}
        </Hero>
        <Score>
          {xp}  
        </Score>
        </>
      )
    }
  }




  return (

        <MaskedSectionAlt>

          <ButtonSelect>
            <FilterButton className={filter == 0 ? 'active' : ''} onClick={() => setFilter(0)}>
              ALL
            </FilterButton>
            <FilterButton className={filter == 1 ? 'active' : ''} onClick={() => setFilter(1)}>
              NINJA
              </FilterButton>
              <FilterButton className={filter == 2 ? 'active' : ''} onClick={() => setFilter(2)}>
              WORLOKK
              </FilterButton>
              <FilterButton className={filter == 3 ? 'active' : ''} onClick={() => setFilter(3)}>
                SOHEI
              </FilterButton>
              <FilterButton className={filter == 4 ? 'active' : ''} onClick={() => setFilter(4)}>
              RONIN
              </FilterButton>
          </ButtonSelect>

          <Grid>
            <GridTitle>
              RANK
            </GridTitle>
            <GridTitleTwo>
              PLAYER
              </GridTitleTwo>
              <GridTitleThree>
              HERO
              </GridTitleThree>
              <GridTitleFour>
              SCORE
              </GridTitleFour>

              {leaderboardData.map(({ account, xp, hero }, key) => (
                getLeaderboardRow(account, hero, xp, key)
              ))}



          </Grid>

          

        </MaskedSectionAlt>

  )
}

const MainBody = styled.div`
  background-color: #DCD5C8;
`;

const ButtonSelect = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: start;
width: 100%;
max-width: 1200px;
margin-bottom: 50px;

`

const FilterButton = styled.button`
display: flex;
flex-direction: row;
border: 1px solid black;
color: black;
width: 85px;
height: 32px;
align-items: center;
justify-content: center;
text-align: center;
background: transparent;
margin-right: 10px;
&.active {
  background: #FF4867;
  color: white;
  border: 1px solid #FF4867;
}
:hover {
  background: #FF4867;
  color: white;
  border: 1px solid #FF4867;
}
`

const Grid = styled.div`
display: grid;
height: 100%;
width: 1200px;

max-width: 1200px;
color: white;
overflow: scroll;

grid-template-columns: 0.2fr repeat(2, 1fr) 0.5fr;
grid-template-rows: repeat(5, 1fr);
grid-column-gap: 0px;
grid-row-gap: 0px;

text-align: center;
`

const GridTitle = styled.div`
  color: black;
  display: flex;
  flex-direction: center;
  align-items: center;
  text-align: center;
  padding: 0.25rem;
  width: 100%;
  height: 50px;
  border-bottom: 2px solid black;

`;
const GridTitleTwo = styled(GridTitle)`
`;
const GridTitleThree = styled(GridTitle)`
`;
const GridTitleFour = styled(GridTitle)`
justify-content: center;

`;


  const Player = styled.div`
  color: black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  
  display: flex;
  flex-direction: center;
  align-items: center;
  text-align: center;
  padding: 0.25rem;
  width: 100%;
  height: 100px;

  `;

  const Rank = styled(Player)`
  font-size: 30px;
  font-family: 'YokaiKingdom';
`;
  const Hero = styled(Player)`

  `;
  const Score = styled(Player)`
  justify-content: center;

  `;



const MaskedSectionAlt = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: white;

  height: calc(100vh - 200px);

  @media (max-width: 500px) {
    padding-top: 0px;


  }

`;


