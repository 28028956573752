import React, {useState, useContext} from 'react'
import axios from 'axios'
import { Screen } from '../../common/layout_account/Screen'

import HeaderBG from './assets/header_bg.png'
import AccountBG from './assets/bg_account.png'
import GAME_IMAGE from './assets/game_image-min.png'
import BetaImage from './assets/OnisQuest_BETA-min.png'
import hyperplaybadge from './assets/hyperplaybadge.png'


import styled, { css, keyframes } from "styled-components";
import ReactCodeInput from "react-code-input";

import AuthProvider, { AuthContext, AuthIsSignedIn, AuthIsNotSignedIn } from './contexts/authContext'

import {animated, useSpring, config, useSpringRef, useChain} from "react-spring";

function Checkbox({isChecked, setIsChecked}) {
  const checkboxAnimationRef = useSpringRef();
  const checkboxAnimationStyle = useSpring({
    backgroundColor: isChecked ? "#fff" : "#fff",
    fill: isChecked ? "#292929" : "#ffff",
    border: '2px solid black',
    config: config.gentle,
    ref: checkboxAnimationRef,
    width: '20px',
    height: '20px'
  });


  const [checkmarkLength, setCheckmarkLength] = useState(0);

  const checkmarkAnimationRef = useSpringRef();
  const checkmarkAnimationStyle = useSpring({
    x: isChecked ? 0 : checkmarkLength,
    config: config.gentle,
    ref: checkmarkAnimationRef
  });

  useChain(
    isChecked
      ? [checkboxAnimationRef, checkmarkAnimationRef]
      : [checkmarkAnimationRef, checkboxAnimationRef],
    [0, 0.1]
  );

  return (
    <DefaultCheckboxBody>
      <DefaultCheckbox
        type="checkbox"
        onChange={() => {
          setIsChecked(!isChecked);
        }}
      />
      <animated.svg
        style={checkboxAnimationStyle}
        d="M8.14249 12.9102C6.32297 11.4372 4.74344 9.92402 2.99984 8.50057C2.44394 9.12141 1.91501 9.68088 1.43013 10.2569C0.94525 10.8329 0.325671 11.305 0.00976562 12.0675C4.20715 15.0347 8.37025 17.969 12.5358 20.915C12.6483 20.9624 12.7684 20.9912 12.8909 21C12.9736 20.8266 13.0471 20.6494 13.1113 20.4689C13.5398 19.0525 14.01 17.6574 14.3749 16.2198C15.0975 13.3637 16.0137 10.5564 17.1176 7.81599C18.0972 5.42942 18.9764 3.01451 19.8947 0.609049C19.9657 0.4202 20.1396 0.210107 19.831 -0.00942993C18.5111 0.679868 17.1642 1.36444 15.8369 2.08443C15.5412 2.26649 15.2782 2.49372 15.0581 2.75721C13.9659 3.93751 12.8786 5.12962 11.8134 6.33589C11.5147 6.68303 11.2575 7.06155 11.0469 7.46426C10.273 8.90187 9.52366 10.3537 8.76451 11.8007C8.5784 12.143 8.38982 12.4688 8.14249 12.9102Z"
        stroke="#fff"
        className={`checkbox ${isChecked ? "checkbox--active" : ""}`}
        // This element is purely decorative so
        // we hide it for screen readers
        aria-hidden="true"
        viewBox="0 0 20 20"
        fill="none"
      >
        <animated.path
          d="M8.14249 12.9102C6.32297 11.4372 4.74344 9.92402 2.99984 8.50057C2.44394 9.12141 1.91501 9.68088 1.43013 10.2569C0.94525 10.8329 0.325671 11.305 0.00976562 12.0675C4.20715 15.0347 8.37025 17.969 12.5358 20.915C12.6483 20.9624 12.7684 20.9912 12.8909 21C12.9736 20.8266 13.0471 20.6494 13.1113 20.4689C13.5398 19.0525 14.01 17.6574 14.3749 16.2198C15.0975 13.3637 16.0137 10.5564 17.1176 7.81599C18.0972 5.42942 18.9764 3.01451 19.8947 0.609049C19.9657 0.4202 20.1396 0.210107 19.831 -0.00942993C18.5111 0.679868 17.1642 1.36444 15.8369 2.08443C15.5412 2.26649 15.2782 2.49372 15.0581 2.75721C13.9659 3.93751 12.8786 5.12962 11.8134 6.33589C11.5147 6.68303 11.2575 7.06155 11.0469 7.46426C10.273 8.90187 9.52366 10.3537 8.76451 11.8007C8.5784 12.143 8.38982 12.4688 8.14249 12.9102Z"
          stroke="#fff"
          viewBox="0 0 10 10"
          width="10"
          height="10"
        />
      </animated.svg>
      &nbsp; I accept the
      <AccentButtonLink href="https://yokai-kingdom.com/privacy"  target="_blank">
      &nbsp; Privacy Policy
      </AccentButtonLink>
      &nbsp; and
      <AccentButtonLink href="https://yokai-kingdom.com/yokaikingdom-termsandconditions"  target="_blank">
      &nbsp; Terms of Service
      </AccentButtonLink>

    </DefaultCheckboxBody>
  );
}

export const AccountScreen = () => {

  const [isRegChecked, setIsRegChecked] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const [passwordInput, setPasswordInput] = useState('');
  const [confirmPasswordInput, setConfirmPasswordInput] = useState('');

  const [playImage, setPlayImage] = useState(0);
  const [playImageAnim, setPlayImageAnim] = useState(false);

  const setActiveImageButtonName = (name: number) => name == playImage ? 'active' : ''
  const setActiveImageButtonNameBool = (name: number) => name == playImage ? true : false

  const [width, setWidth] = useState(window.innerWidth)


  //AWS
  const [section, setSection] = useState('login')
  const authContext = useContext(AuthContext)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [credentials, setCredentials] = useState<any>(null)

  const [code, setCode] = useState('')
  const [username, setUsername] = useState('')
  const [suffix, setSuffix] = useState('')

  const [downloads, setDownloads] = useState({
      mac: 'https://store.hyperplay.xyz/game/onisquest',
      win: 'https://store.hyperplay.xyz/game/onisquest'
    })

  const [created, setCreated] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [loading, setLoading] = useState(false)
  const [newPassword, setNewPassword] = useState('')



  React.useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth)
    }

    if(authContext.authStatus == 1 && window.localStorage.getItem('email')){
      setCredentials({email: window.localStorage.getItem('email')!})
    }


    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [authContext]);


  const reset = () => {
    setNewPassword('')
    setCode('')
    setEmailInput('')
    setPasswordInput('')
    setConfirmPasswordInput('')
  }
  const signUpClicked = async () => {
    try {
      setLoading(true)
      await authContext.signUpWithEmail(emailInput.toLowerCase(), passwordInput)

      setError('')
      setSection('verify')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
    setLoading(false)
  }

  const confirmCode = async () => {
    try {
      setLoading(true)
      await authContext.verifyCode(emailInput.toLowerCase(), code)
      setError('')
      reset()
      setSection('login')
      setSuccess('Account created successfully. Please login.')
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message)
      }
    }
    setLoading(false)

  }

  const signInClicked = async () => {
    try {
      setLoading(true)
      if(!credentials){
        await authContext.signInWithEmail(emailInput.toLowerCase(), passwordInput)
        setCredentials({email: emailInput.toLowerCase()})
        try{
          await axios.create().get('https://subscribe.yokaikingdom.workers.dev/email/add?email=' + emailInput.toLowerCase())
        } catch(e){

        }
      } else {
        try{
          await axios.create().get('https://subscribe.yokaikingdom.workers.dev/email/add?email=' + credentials.email)
        } catch(e){

        }
      }

      var info = await authContext.getDownloadInfo(credentials)

      setDownloads(info.launcher)
      setUsername(info.name)
      setSuffix(info.suffix)
      setError('')
      setSection('loggedin')
    } catch (err: any) {
      console.log(err)
      if (err.code === 'UserNotConfirmedException') {
        setSection('verify')
      } else {
        setError(err.message)
      }
    }
    setLoading(false)
  }

  const forgotPasswordCode = async () => {
    try{
      setLoading(true)
      await authContext.sendCode(emailInput.toLowerCase())
      setSection('forgotpassword')
    } catch(e){

    }
    setLoading(false)

  }

  const resetPassword = async () => {
    setLoading(true)
    try{
      await authContext.forgotPassword(emailInput.toLowerCase(), code, newPassword)
      reset()
      setSection('login')
    } catch(e){

    }
    setLoading(false)

  }


  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return String(password).length >= 8
  };



  const handleKeypressSignIn = e => {
    //it triggers by pressing the enter key
    if(!validateEmail(emailInput) || !validatePassword(passwordInput) || loading)
      return
    if (e.charCode === 13) {

      signInClicked();
    }
  };

  const handleKeypressSignUp = e => {
    //it triggers by pressing the enter key
    if(!validateEmail(emailInput) || !validatePassword(passwordInput) || !isRegChecked || loading)
      return

    if (e.charCode === 13) {
      signUpClicked();
    }
  };

  const handleKeypressConfirmCode = e => {
    //it triggers by pressing the enter key

    if (e.charCode === 13) {
      confirmCode();
    }
  };

  const handleKeypressResetPassword = e => {
    //it triggers by pressing the enter key
    if(!validatePassword(newPassword) || loading)
      return

    if (e.charCode === 13) {
      resetPassword();
    }
  };


  const forgetUser = () => {
    window.localStorage.clear()
    setCredentials(null)
  };


  const getSection = () => {

    if(section == 'login'){
      return (
        <>
        <HeaderSubText>
          ACCOUNT
        </HeaderSubText>
        <HeaderText>
          LOGIN
        </HeaderText>

        <Linebreak/>
        <Linebreak/>
        <Linebreak/>

        <InputParent style={{alignItems: 'center'}}>



        {credentials && credentials!.email! != '' ? (

                    <>
                      <SectionHeaderText>
                      Logging in as {credentials.email!}
                      <Linebreak/>
                      {loading == true ? (<></>) : (
                        <AccentButton onClick={() => {forgetUser()}}>
                          Not you?
                        </AccentButton>
                      )}
                      </SectionHeaderText>

                    </>
        ) :
        (<>
          <DefaultInputParent style={{alignItems: 'center',}}>
          <SectionHeaderText>Email</SectionHeaderText>
          <DefaultInput
            placeholder="Email"
            value={emailInput}
            onKeyPress={handleKeypressSignIn}
            onChange={(e) => setEmailInput(e.target.value)}
          />
          </DefaultInputParent>
          <Linebreak/>
          <DefaultInputParent style={{alignItems: 'center'}}>
          <SectionHeaderText style={{display: 'flex'}}>Password
          </SectionHeaderText>
          <DefaultInput
            placeholder="Password"
            value={passwordInput}
            onKeyPress={handleKeypressSignIn}
            onChange={(e) => setPasswordInput(e.target.value)}
            type='password'
          />
          <AccentButton onClick={() => {forgotPasswordCode()}} style={{textAlign: 'right', alignItems: 'flex-end', width: '100%'}}>
          Forgot password?
          </AccentButton>
          </DefaultInputParent>
        </>
      )}



            <Linebreak/>
            <Linebreak/>
            <DefaultButton onClick={signInClicked}  disabled={(!credentials && (!validateEmail(emailInput) || !validatePassword(passwordInput))) || loading}>
              {loading == true ? <DualRing/> : <ButtonText>Login</ButtonText> }

            </DefaultButton>

            <Linebreak/>
            <Linebreak/>
            <SectionTextFull style={{color: '#E84E4E'}}>{error}</SectionTextFull>
            <SectionTextFull style={{color: '#7BE5C4'}}>{success}</SectionTextFull>


        </InputParent>
        <Linebreak/>
        <Linebreak/>

        <AccentText>
        Don't have an account?
        <AccentButton onClick={() => {setSection('signup')}} >
        &nbsp; Create
        </AccentButton>
        </AccentText>
        </>

      )
    } if(section == 'loggedin'){
          return (
            <>
            <HeaderText>
              YOUR ACCOUNT
            </HeaderText>
            <InputParent>

                <DefaultInputParent>
                <SectionHeaderText>Email</SectionHeaderText>
                <Linebreak/>
                <RegularText>{credentials.email}</RegularText>
                </DefaultInputParent>
                <Linebreak/>
                <Linebreak/>
                <DefaultInputParent>
                <SectionHeaderText>Username</SectionHeaderText>
                <Linebreak/>
                <RegularText>{username + '#' + suffix}</RegularText>
                </DefaultInputParent>
                <Linebreak/>
                <Linebreak/>

                { downloads != null ? (
                  <>
                  <DefaultInputParent>
                  <SectionHeaderText>Download Oni's Quest on HyperPlay</SectionHeaderText>
                  <Linebreak/>
                  <div style={{display: 'flex', flexDirection: 'row', width: '300px', justifyContent: 'space-between'}}>

                  <DefaultButtonHyperplay onClick={() => window.open('https://store.hyperplay.xyz/game/onisquest', "_blank")} >
                  </DefaultButtonHyperplay>

                  </div>
                  </DefaultInputParent>
                  </>
                ) : (
                  <></>
                )}

                <Linebreak/>
                <Linebreak/>

                <DefaultInputParent>
                <SectionHeaderText>Need to change any of these details?</SectionHeaderText>
                <Linebreak/>
                  <AccentButtonLink href="https://discord.gg/FncuawMDyZ"  target="_blank">
                    Join Yokai Kingdom Discord
                  </AccentButtonLink>
                </DefaultInputParent>
                <Linebreak/>
                <Linebreak/>
                <SectionTextFull style={{color: '#E84E4E'}}>{error}</SectionTextFull>

            </InputParent>
            </>

          )
    } else if (section == 'signup'){
      return (
        <>
        <HeaderTextSmall>
          Step 1 of 2
        </HeaderTextSmall>
        <HeaderText>
          CREATE ACCOUNT
        </HeaderText>

        <Linebreak/>
        <InputParent style={{alignItems: 'center'}}>

            <DefaultInputParent style={{alignItems: 'center'}}>
            <SectionHeaderText>Email</SectionHeaderText>
            <DefaultInput
              placeholder="Email"
              value={emailInput}
              onChange={(e) => setEmailInput(e.target.value)}
              onKeyPress={handleKeypressSignUp}
            />
            </DefaultInputParent>
            <Linebreak/>
            <DefaultInputParent style={{alignItems: 'center'}}>
            <SectionHeaderText>Password</SectionHeaderText>
            <DefaultInput
              placeholder="Password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              type='password'
              onKeyPress={handleKeypressSignUp}
            />
            </DefaultInputParent>
            <Linebreak/>
            <DefaultInputParent style={{alignItems: 'center'}}>
            <SectionHeaderText>Confirm password</SectionHeaderText>
            <DefaultInput
              placeholder="Confirm Password"
              value={confirmPasswordInput}
              onChange={(e) => setConfirmPasswordInput(e.target.value)}
              type='password'
              onKeyPress={handleKeypressSignUp}
            />
            </DefaultInputParent>

            <Linebreak/>
            <Checkbox
            isChecked={isRegChecked}
            setIsChecked={setIsRegChecked}
            />
            <Linebreak/>

            <Linebreak/>
            <Linebreak/>
            <DefaultButton onClick={signUpClicked}  disabled={!isRegChecked || !validateEmail(emailInput) || loading}>
              {loading == true ? <DualRing/> : <ButtonText>Create Account</ButtonText> }

            </DefaultButton>

            <Linebreak/>
            <Linebreak/>
            <SectionTextFull style={{color: '#E84E4E'}}>{error}</SectionTextFull>

        </InputParent>
        <Linebreak/>
        <Linebreak/>

        <AccentText>
        Already have an account?
        <AccentButton onClick={() => {setSection('login')}} >
        &nbsp; Login
        </AccentButton>
        </AccentText>
        </>
      )
    } else if (section == 'verify'){
      return (
        <>
        <HeaderTextSmall>
          Step 2 of 2
        </HeaderTextSmall>
        <HeaderText>
          CREATE ACCOUNT
        </HeaderText>
        <InputParent style={{alignItems: 'center'}}>

            <DefaultInputParent style={{alignItems: 'center'}}>
            <SectionHeaderText>We've sent an email to {emailInput}. Please enter the 6 digit code below to finish creating your account.</SectionHeaderText>
            <Linebreak/>
            <Linebreak/>
            <Linebreak/>
            <Linebreak/>

            <ReactCodeInput
              type='text'
              fields={6}
              name="code"
              pattern="[0-9]"
              inputMode="numeric"
              value={code}
              onChange={(e) => setCode(e)}
            />
            </DefaultInputParent>
            <Linebreak/>
            <Linebreak/>
            <Linebreak/>
            <Linebreak/>
            <DefaultButton onClick={confirmCode} disabled={loading}>
            {loading == true ? <DualRing/> : <ButtonText>Confirm Code</ButtonText> }
            </DefaultButton>

            <Linebreak/>
            <Linebreak/>
            <SectionTextFull style={{color: '#E84E4E'}}>{error}</SectionTextFull>
        </InputParent>
        <Linebreak/>
        <Linebreak/>

        <AccentText>
        Already have an account?
        <AccentButton onClick={() => {setSection('login')}} >
        &nbsp; Login
        </AccentButton>
        </AccentText>
        </>
      )
    } else if (section == 'forgotpassword'){
      return (
        <>
        <HeaderText>
          RESET PASSWORD
        </HeaderText>
        <InputParent style={{alignItems: 'center'}}>
            <DefaultInputParent style={{alignItems: 'center'}}>
            <SectionHeaderText>We've sent an email to {emailInput}. Please enter the 6 digit code below and a new password.</SectionHeaderText>
            <Linebreak/>
            <Linebreak/>
            <Linebreak/>
            <Linebreak/>

            <ReactCodeInput
              type='text'
              fields={6}
              name="code"
              pattern="[0-9]"
              inputMode="numeric"
              value={code}
              onChange={(e) => setCode(e)}
            />
            </DefaultInputParent>
            <Linebreak/>
            <Linebreak/>
            <DefaultInputParent style={{alignItems: 'center'}}>
            <SectionHeaderText>New password</SectionHeaderText>
            <DefaultInput
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              type='password'
              onKeyPress={handleKeypressResetPassword}
            />
            </DefaultInputParent>

            <Linebreak/>
            <Linebreak/>

            <DefaultButton onClick={resetPassword} disabled={loading}>
              {loading == true ? <DualRing/> : <ButtonText>Reset Password</ButtonText> }
            </DefaultButton>

            <Linebreak/>
            <Linebreak/>
            <SectionTextFull style={{color: '#E84E4E'}}>{error}</SectionTextFull>
        </InputParent>
        <Linebreak/>
        <Linebreak/>

        <AccentText>
        Already have an account?
        <AccentButton onClick={() => {setSection('login')}} >
        &nbsp; Login
        </AccentButton>
        </AccentText>
        </>
      )
    }
  }

  return (
    <Screen>
        <MainBody>


        <MaskedSectionAlt>

          {
            section != 'loggedin' ? (
              <>
                <AccountBackground/>

                <SectionWrapper>
                {getSection()}
                </SectionWrapper>
              </>
            ) : 
            (
              <>
              <SectionWrapperMod>
              {getSection()}
              </SectionWrapperMod>
              <AccountBackgroundMod>

                <OniHeader src={BetaImage}/>
                <GameImage src={GAME_IMAGE}/>

              </AccountBackgroundMod>
            </>
            )
          }

          

        </MaskedSectionAlt>

        </MainBody>

    </Screen>
  )
}

const MainBody = styled.div`
  background-color: #DCD5C8;

`;

const SectionWrapper = styled.div`
  justify-content: space-around;
  align-items: center;
  text-align: center;
  display: flex;
  width: 35%;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  height: 100%;
  @media (max-width: 1100px) {
    width: 45%;
  }
  @media (max-width: 900px) {
    width: 100%
  }
`;

const SectionWrapperMod = styled.div`
  justify-content: space-around;
  align-items: flex-start;
  text-align: center;
  padding-left: 50px;
  display: flex;
  width: 70%;
  padding-top: 50px;
  padding-bottom: 50px;
  flex-direction: column;
  height: 100%;
  
  @media (max-width: 900px) {
    width: 100%;
    padding-left: 0px;
    align-items: center;


  }
`;

const AccountBackground = styled.div`
  width: 65%;
  height: 100%;
  justify-content: center;
  background-image: url(${AccountBG});
  background-size: cover;
  align-items: center;
  text-align: center;

  @media (max-width: 1100px) {
    width: 55%;
  }

  @media (max-width: 900px) {
    width: 0%;
    display: none;
  }
`;

const AccountBackgroundMod = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  padding-top: 100px;
  background: #FF4867;
  background-size: cover;
  align-items: flex-start;
  text-align: center;
  @media (max-width: 900px) {
    width: 0%;
    display: none;
  }
`;

const OniHeader = styled.img`

width: 50%;
height: auto;

`

const GameImage = styled.img`

width: 100%;
height: auto;
position: absolute;
bottom: 0;


`

const SectionHeaderText = styled.p`
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.09em;
  color: black;
  width: 100%;

  @media (max-width: 950px) {

  }

`

const RegularText = styled.p`
  display: flex;
  flex-direction: row;
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.09em;
  color: black;
  width: auto;
  text-align: left;
  align-items: flex-start;
  border: 1px solid black;
  border-radius: 8px;
  padding: 0px 10px;

  @media (max-width: 950px) {

  }

`

const Linebreak = styled.div`
  height: 20px;
`

const HeaderText = styled.p`
  font-family: 'YokaiKingdom';
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  letter-spacing: 0.01em;
  color: black;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    font-size: 36px;
  }
`;

const HeaderSubText = styled.p`
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  padding-top: 5px;
  letter-spacing: 0.01em;
  color: black;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
  }
  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const HeaderTextSmall = styled.p`
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  letter-spacing: 0.01em;
  line-height: 200%;
  color: black;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
  }

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const SectionText = styled.p`
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #302C24;
  width: 65%;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const AccentText = styled.p`
  display: flex;
  flex-direction: row;
  font-family: 'Quattrocento';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 1%;
  color: black;

  @media (max-width: 500px) {
    font-size: 18px;
  }
`;

const AccentButton = styled.p`
  color: #ff4867;
  cursor: pointer;
`;

const AccentButtonLink = styled.a `
color: #ff4867;
cursor: pointer;
`

const ButtonText = styled.p`
  font-family: 'Staatliches';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #292929;
  width: 100%;

  @media (max-width: 950px) {
    width: 100%;
  }
`;

const SectionTextFull = styled(SectionText)`
  width: 100%;
  font-size: 16px;
`


const InputParent = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  align-items: center;

  @media (max-width: 1000px) {
  }
`

const MaskedSectionAlt = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;

  justify-content: space-around;
  align-items: center;
  text-align: center;
  background: white;

  height: calc(100vh - 200px);

  @media (max-width: 500px) {
    padding-top: 0px;


  }

`;


const DefaultButton = styled.button`
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 50px;
  width: 350px;
  cursor: pointer;
  position: relative;
  background: #ff4867;
  border: 1px solid transparent;
  border-radius: 5px;
  @media (max-width: 500px) {
    height: 50px;
    width: 90%;
  }

  :disabled{
    opacity: 0.5;
  }

  :hover {
    opacity: 0.5;
  }
`;


const DefaultButtonHyperplay = styled.img`
  background-image: url(${hyperplaybadge});
  justify-content: center;
  align-items: center;
  text-align: center;
  background-size: cover;
  height: 55px;
  width: 240px;
  cursor: pointer;
  position: relative;
  border: 1px solid transparent;
  border-radius: 5px;
  @media (max-width: 500px) {
    height: 50px;
    width: 90%;
  }

  :disabled{
    opacity: 0.5;
  }

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const DefaultButtonOS = styled.button`
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 45px;
  width: 296px;
  cursor: pointer;
  position: relative;
  @media (max-width: 950px) {
    height: 45px;

  }

  :disabled{
    opacity: 0.5;
  }

  :hover {
    opacity: 0.5;
  }
`;


const DefaultButtonImg = styled.img`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 0;

  @media (max-width: 950px) {

  }

`;


const DefaultInputParent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 350px;
  height: auto;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 500px) {

    width: 90%;

  }
`
const DefaultInput = styled.input`
  height: 50px;
  width: 100%;
  cursor: pointer;
  position: relative;
  padding-left: 20px;
  font-family: 'Quattrocento';
  font-weight: 700;
  font-size: 18px;
  color: black;
  border: 2px solid black;
  @media (max-width: 500px) {
    height: 45px;
    padding-left: 20px;

  }

`;


const DefaultCheckbox = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  align-items: flex-start;
  justify-content: flex-start;
  width: 20px;
  height: 20px;
  left: 0;
`;

const DefaultCheckboxBody = styled.label`
  color: black;
  font-family: 'Quattrocento';
  font-weight: 700;
  font-size: 16px;
  height: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  @media (max-width: 500px) {
    font-size: 12px;
  }
`;



const dualRingAnim = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
const DualRing = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;

  :after {
    content: " ";
    display: block;
    width: 20px;
    height: 20px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #000000;
    border-color: #000000 transparent #000000 transparent;
    animation: ${dualRingAnim} 1.2s linear infinite;
  }
`
