import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "./assets/yokai_header.png";
import { useToggler } from "../../hooks/useToggler";
import { useClickOutside } from "../../hooks/useClickOutside";


export const Header = () => {
  const { visible, toggle } = useToggler()
  const ref = useRef(null)
  useClickOutside(ref, () => toggle(false))

  const [selectedLink, setSelectedLink] = React.useState('')
  const setActiveClassName = (name: string) => name == selectedLink ? 'active' : ''
 
  return (
    <HeaderRow>
      <HeaderSection>
        <LogoLink style={{color: 'gray'}} onClick={() => window.open('https://yokai-kingdom.com', "_blank")}>
          <FixedMenu src={logo} alt="logo" />
        </LogoLink>
      </HeaderSection>
    </HeaderRow>
  )
}


const FixedSVG = styled.img`
  height: 100%;
  width: auto;
`

const FixedMenu = styled.img`
  height: calc(200px/3);
  width: calc(480px/3);
`

const HeaderRow = styled.header`
  height: 60px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px;
  background: black;
  z-index: 5;

  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 1200px) {
    padding: 8px 16px;
  }
`;

const HeaderSection = styled.div`
  width: 130px;
`;

const HeaderImage = styled.img`
  height: 60px;
  width: auto;
  @media (max-width: 1200px) {
    height: 40px;
  }
`;

const HeaderImageHover = styled.img`
  height: 60px;
  width: auto;
  display: none;
  @media (max-width: 1200px) {
    height: 40px;
  }
`;

const Preview = styled.div`
font-family: 'Mute Logo Font';
text-transform: uppercase;
font-size: 14px;
line-height: 13px;
text-transform: uppercase;
color: #FFFFFF;
`;

const Menu = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1200px) {
    margin-left: 24px;
  }
  @media (max-width: 1200px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 224px;
    height: 100%;
    margin: 0;
    background: #292929;
    z-index: 99999;
  }
  &.visible {
    display: flex;
    flex-direction: column;
  }
`;

const MenuHeader = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 100%;

  @media (max-width: 1200px) {
    height: 129px;
    display: flex;
    border-bottom: 2px solid #E7DDA0;
  }
`;

const MenuContent = styled.div`
  justify-content: space-between;
  height: 100%;
  padding-left: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 48px 32px 32px;
  }
`;


const MenuOpenButton = styled.button`
  display: none;
  margin-right: 8px;
  padding: 0;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MenuCloseButton = styled.button`
  margin-left: auto;
`;


const NavList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Link = styled(NavLink)`
  font-family: 'Merriweather';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 3px rgba(0,0,0,0) solid;
  padding-bottom: 10px;

  &:hover ${HeaderImageHover} {
    display: inline;
  }

  &:hover ${HeaderImage} {
      display: none;
  }

  &.active ${HeaderImageHover} {
    display: inline;
  }


  &.active ${HeaderImage} {
      display: none;
  }

  &.active {
    color: #E7DDA0 !important;
    border-bottom: 3px #E7DDA0 solid;
  }


  &:hover {
    color: #E7DDA0 !important;
    border-bottom: 3px #E7DDA0 solid;
  }

  &.disabled {

    opacity: 0.3

  }

  &.disabled:hover {
    color: #FFFFFF !important;
    border-bottom: 3px rgba(0,0,0,0) solid;
  }

  &.disabled:hover ${HeaderImageHover} {
    display: none;
  }

  &.disabled:hover ${HeaderImage} {
      display: inline;
  }


  @media (max-width: 1200px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;

const LogoLink = styled.a`
  font-family: 'Merriweather';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 3px rgba(0,0,0,0) solid;
  cursor: pointer;

  @media (max-width: 1200px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;


const NavItem = styled.li`
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  display: flex;

  padding-left: 10px;
  padding-right: 10px;


  & + & {
    @media (max-width: 1200px) {
      margin: 56px 0 0;
    }
  }

  @media (max-width: 1200px) {
    height: auto;
    padding: 0px;
    justify-content: flex-start;
    width: 100%;
  }

  `;

const ConnectionWrapper = styled.div`
  width: auto;
`;
