import { Switch, Route } from "react-router-dom";

import { Footer } from "./pages/Home/Footer";
import { PrivacyScreen } from "./pages/Privacy/PrivacyScreen";
import { TermsScreen } from "./pages/Terms/TermsScreen";
import { AccountScreen } from "./pages/Account/AccountScreen";
import { LeaderboardScreen } from "./pages/Leaderboard/LeaderboardScreen";
import { Providers } from "./providers";
import "./styles/reset.css";
import "./styles/style.css";

import AuthProvider from './pages/Account/contexts/authContext'


export function App() {

  if (window.location.host.split(".")[0] == "account") {
      return (
        <AuthProvider>
        <Providers>
          <Switch>
            <Route exact path="/" component={AccountScreen} />
            <Route exact path="/privacy" component={PrivacyScreen} />
            <Route exact path="/terms" component={TermsScreen} />
          </Switch>
          <Footer/>
        </Providers>
        </AuthProvider> 
      ); 
  } else if(window.location.host.split(".")[0] == "leaderboard") {
      return (
        <>
        <Providers>
          <Switch>
            <Route exact path="/" component={LeaderboardScreen} />
            <Route exact path="/privacy" component={PrivacyScreen} />
            <Route exact path="/terms" component={TermsScreen} />
          </Switch>
          <Footer/>
        </Providers>
        </>
      );
  } else {
    return (
    <>
    </>
    )
  }


}



