import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import logo from "./../../assets/images/YokaiKindgom_White-1.png";
import menuIcon from "./../../assets/images/icons/hamburger.svg";
import closeIcon from './../../assets/images/icons/cross-grey.svg'
import { useToggler } from "../../hooks/useToggler";
import { useClickOutside } from "../../hooks/useClickOutside";

export const Header = () => {
  const { visible, toggle } = useToggler()
  const ref = useRef(null)
  useClickOutside(ref, () => toggle(false))

  const [selectedLink, setSelectedLink] = React.useState('')
  const setActiveClassName = (name: string) => name == selectedLink ? 'active' : ''

  return (
    <HeaderRow>
      <HeaderSection>
        <LogoLink style={{color: 'gray'}} onClick={() => setSelectedLink('')}  to="/">
          <FixedMenu src={logo} alt="logo" />
        </LogoLink>
      </HeaderSection>
      <MenuOpenButton onClick={() => toggle(!visible)}>
        <FixedSVG src={menuIcon} alt="Menu"/>
      </MenuOpenButton>
      <Menu ref={ref} className={visible ? 'visible' : ''}>
        <MenuHeader>
          <FixedMenu src={logo} alt="logo" />
        </MenuHeader>
        <MenuContent>
          <nav style={{height: '100%'}}>
            <NavList>
              <NavItem>
                <Link onClick={() => setSelectedLink('home')} className={setActiveClassName('home')} to="/">
                Home
                </Link>
              </NavItem>
              <NavItem>
                <Link onClick={() => setSelectedLink('about')} className={setActiveClassName('about')} to="/about">
                About
                </Link>
              </NavItem>
              <NavItem>
                <LinkSub target="_blank" href="https://app.yokai-kingdom.com">
                Marketplace
                </LinkSub>
              </NavItem>

            </NavList>

          </nav>

          <ConnectionWrapper>
          <div className="footer-links">
            <a href="https://opensea.io/collection/yokai-kingdom" target="_blank" className="footer-item-os"></a>
            <a href="https://discord.gg/FncuawMDyZ" target="_blank" className="footer-item-discord"></a>
            <a href="https://twitter.com/yokai_kingdom" target="_blank" className="footer-item"></a>
          </div>
          </ConnectionWrapper>

        </MenuContent>

      </Menu>
    </HeaderRow>
  )
}


const FixedSVG = styled.img`
  height: 100%;
  width: auto;
`

const FixedMenu = styled.img`
  height: 80px;
  width: 122px;
`

const HeaderRow = styled.header`
  height: 130px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 32px;
  background: #292929;
  z-index: 5;

  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 1200px) {
    padding: 8px 16px;
  }
`;

const HeaderSection = styled.div`
  width: 130px;
`;

const HeaderImage = styled.img`
  height: 60px;
  width: auto;
  @media (max-width: 1200px) {
    height: 40px;
  }
`;

const HeaderImageHover = styled.img`
  height: 60px;
  width: auto;
  display: none;
  @media (max-width: 1200px) {
    height: 40px;
  }
`;

const Preview = styled.div`
font-family: 'Mute Logo Font';
text-transform: uppercase;
font-size: 14px;
line-height: 13px;
text-transform: uppercase;
color: #FFFFFF;
`;

const Menu = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 1200px) {
    margin-left: 24px;
  }
  @media (max-width: 1200px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 224px;
    height: 100%;
    margin: 0;
    background: #292929;
    z-index: 99999;
  }
  &.visible {
    display: flex;
    flex-direction: column;
  }
`;

const MenuHeader = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 25px;
  width: 100%;

  @media (max-width: 1200px) {
    height: 129px;
    width: auto;
    display: flex;
    border-bottom: 2px solid #E7DDA0;
  }
`;

const MenuContent = styled.div`
  justify-content: space-between;
  height: 100%;
  padding-left: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 1200px) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 48px 32px 32px;
  }
`;


const MenuOpenButton = styled.button`
  display: none;
  margin-right: 8px;
  padding: 0;

  @media (max-width: 1200px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MenuCloseButton = styled.button`
  margin-left: auto;
`;


const NavList = styled.ul`
  height: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Link = styled(NavLink)`
  font-family: 'Merriweather';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;

  &.active {
    color: #E7DDA0 !important;
  }

  &:hover {
    color: #E7DDA0 !important;
  }

  @media (max-width: 1200px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;

const LinkSub = styled.a`
  font-family: 'Merriweather';
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 400;
  line-height: 13px;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;


  &.active {
    color: #E7DDA0 !important;
  }

  &:hover {
    color: #E7DDA0 !important;
  }

  &.disabled {

    opacity: 0.3

  }

  &.disabled:hover {
    color: #FFFFFF !important;
    border-bottom: 3px rgba(0,0,0,0) solid;
  }

  &.disabled:hover ${HeaderImageHover} {
    display: none;
  }

  &.disabled:hover ${HeaderImage} {
      display: inline;
  }


  @media (max-width: 1200px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;

const LogoLink = styled(NavLink)`
  color: #FFFFFF;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-left: 10px;
  padding-right: 10px;
  border-bottom: 3px rgba(0,0,0,0) solid;

  @media (max-width: 1200px) {
    font-size: 15px;
    justify-content: flex-start;
  }
`;


const NavItem = styled.li`
  width: auto;
  height: 100%;
  justify-content: center;
  align-items: center;

  flex-direction: row;
  display: flex;

  padding-left: 10px;
  padding-right: 10px;


  & + & {
    @media (max-width: 1200px) {
      margin: 56px 0 0;
    }
  }

  @media (max-width: 1200px) {
    height: auto;
    padding: 0px;
    justify-content: flex-start;
    width: 100%;
  }

  `;

const ConnectionWrapper = styled.div`
  width: auto;
`;
