import React, { useState, useEffect, useContext } from 'react'

import * as cognito from '../libs/cognito'
import axios from 'axios'
import axiosRetry from 'axios-retry';

axiosRetry(axios, {
    retries: 3, // number of retries
    retryDelay: (retryCount) => {
        console.log(`retry attempt: ${retryCount}`);
        return retryCount * 1000; // time interval between retries
    },
    retryCondition: (error) => {
        console.log(error)
        console.log('retry!')
        // if retry condition is not specified, by default idempotent requests are retried
        return error!.response!.status != 200;
    },
});

export enum AuthStatus {
  Loading,
  SignedIn,
  SignedOut,
}

export interface IAuth {
  sessionInfo?: { username?: string; email?: string; sub?: string; accessToken?: string; refreshToken?: string }
  attrInfo?: any
  authStatus?: AuthStatus
  signInWithEmail?: any
  signUpWithEmail?: any
  signOut?: any
  verifyCode?: any
  getSession?: any
  sendCode?: any
  forgotPassword?: any
  changePassword?: any
  getAttributes?: any
  getDownloadInfo?: any
  setAttribute?: any
}

const defaultState: IAuth = {
  sessionInfo: {},
  authStatus: AuthStatus.Loading,
}

type Props = {
  children?: React.ReactNode
}

export const AuthContext = React.createContext(defaultState)

export const AuthIsSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedIn ? children : null}</>
}

export const AuthIsNotSignedIn = ({ children }: Props) => {
  const { authStatus }: IAuth = useContext(AuthContext)

  return <>{authStatus === AuthStatus.SignedOut ? children : null}</>
}

const AuthProvider = ({ children }: Props) => {
  const [authStatus, setAuthStatus] = useState(AuthStatus.Loading)
  const [sessionInfo, setSessionInfo] = useState({})
  const [credentials, setCredentials] = useState({})
  const [attrInfo, setAttrInfo] = useState([])

  useEffect(() => {
    async function getSessionInfo() {
      try {
        const session: any = await getSession()
        setSessionInfo({
          accessToken: session.accessToken.jwtToken,
          refreshToken: session.refreshToken.token,
          idToken: session.idToken.jwtToken
        })
        window.localStorage.setItem('accessToken', `${session.accessToken.jwtToken}`)
        window.localStorage.setItem('idToken', `${session.idToken.jwtToken}`)
        window.localStorage.setItem('refreshToken', `${session.refreshToken.token}`)
        setAuthStatus(AuthStatus.SignedIn)
      } catch (err) {
        setAuthStatus(AuthStatus.SignedOut)
      }
    }
    getSessionInfo()
  }, [setAuthStatus, authStatus])

  if (authStatus === AuthStatus.Loading) {
    return null
  }


  async function getDownloadInfo(){
      try {

        var creds = await cognito.getCognitoLoginInfo(sessionInfo["idToken"])
        setCredentials(creds)        

        const payload = await cognito.getDownloadInfo({getInfo: true}, creds ? creds : credentials)
        var data = JSON.parse(new TextDecoder().decode(payload))
        console.log(data)
        var username_info = await getUsername(creds)
        console.log(username_info)


        return {
          success: data.body.message == 'success',
          downloads: data.body.downloads ? data.body.downloads : null,
          version: data.body.version ? data.body.version : null,
          launcher: data.body.launcher ? data.body.launcher : null,
          name: username_info.name,
          suffix: username_info.suffix
        }
      } catch (err) {
        console.log(err)
        return false
        throw err
      }
  }

  async function getUsername(creds){
    try {
      const payload = await cognito.modifyUserInfo({getInfo: true}, creds)
      var data = JSON.parse(new TextDecoder().decode(payload))
      console.log(data)
      return {
        success: data.body.success == 'success',
        name: data.body.name,
        suffix:  data.body.suffix
      }
    } catch (err) {
      console.log(err)
      return  {
        success: false,
        username: '',
        suffix:  ''
      }
      throw err
    }
  }

  async function changeUsername(name: string, creds){
    try {
      var data = JSON.parse(new TextDecoder().decode(await cognito.modifyUserInfo({name}, creds ? creds : credentials)))
      return {
        success: JSON.parse(data.body).success == 'success',
      }
    } catch (err) {
      console.log(err)
      return false
      throw err
    }
  }

  async function signInWithEmail(email: string, password: string) {
    try {
      await cognito.signInWithEmail(email, password)
      window.localStorage.setItem('email', `${email}`)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      setAuthStatus(AuthStatus.SignedOut)
      throw err
    }
  }

  async function signUpWithEmail(email: string, password: string) {
    try {
      await cognito.signUpUserWithEmail(email, password)
    } catch (err) {
      throw err
    }
  }

  function signOut() {
    cognito.signOut()
    setAuthStatus(AuthStatus.SignedOut)
  }

  async function verifyCode(email: string, code: string) {
    try {
      await cognito.verifyCode(email, code)
      setAuthStatus(AuthStatus.SignedIn)
    } catch (err) {
      throw err
    }
  }

  async function getSession() {
    try {
      const session = await cognito.getSession()
      return session
    } catch (err) {
      throw err
    }
  }

  async function getAttributes() {
    try {
      const attr = await cognito.getAttributes()
      return attr
    } catch (err) {
      throw err
    }
  }

  async function setAttribute(attr: any) {
    try {
      const res = await cognito.setAttribute(attr)
      return res
    } catch (err) {
      throw err
    }
  }

  async function sendCode(email: string) {
    try {
      await cognito.sendCode(email)
    } catch (err) {
      throw err
    }
  }

  async function forgotPassword(email: string, code: string, password: string) {
    try {
      await cognito.forgotPassword(email, code, password)
    } catch (err) {
      throw err
    }
  }

  async function changePassword(oldPassword: string, newPassword: string) {
    try {
      await cognito.changePassword(oldPassword, newPassword)
    } catch (err) {
      throw err
    }
  }

  const state: IAuth = {
    authStatus,
    sessionInfo,
    attrInfo,
    signUpWithEmail,
    signInWithEmail,
    signOut,
    verifyCode,
    getSession,
    sendCode,
    forgotPassword,
    changePassword,
    getAttributes,
    getDownloadInfo,
    setAttribute,
  }

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>
}

export default AuthProvider
