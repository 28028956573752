import React, { ReactNode } from 'react'
import { Main } from './Main'
import { Header } from './Header'

interface ScreenProps {
  children: ReactNode;
}

export const Screen = ({children}: ScreenProps) => {
  return (
    <>
      <Header/>
      <Main>
        {children}
      </Main>
    </>
  )
}
